@tailwind base;
@tailwind components;
@tailwind utilities;

.no-context-menu {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

